<template>
  <div class="page">
    <div class="top_card">
      <el-form label-width="100px">
        <el-form-item label="所在地区：">
          <city-select
            ref="child"
            :province="queryInfo.provinces"
            :city="queryInfo.city"
            :district="queryInfo.district"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
            @searchDistrict="searchDistrict"
          />
        </el-form-item>
        <div class="row">
          <div class="btn_list">
            <el-button type="primary" @click="addClick">新增</el-button>
            <el-checkbox
              style="margin-left: 10px"
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="checkAllChanged"
              >全选</el-checkbox
            >
          </div>
          <!-- 右边搜索 -->
          <div class="search_right">
            <el-input
              placeholder="请输入运营商名称"
              v-model="queryInfo.keys"
              clearable
              class="input-with-select"
            >
              <el-button slot="append" @click="changeSearchangeSearch" type="primary"
                >搜索</el-button
              >
            </el-input>
          </div>
        </div>
      </el-form>
    </div>
    <div class="center_row">
      <div class="row_left">
        <div class="item">
          <span class="txt">当前条件下共查出</span>
          <span class="txt color1">{{ total }}</span>
          <span class="txt">家运营商</span>
        </div>
        <div class="sort">
          <span @click="handleSort">按企业</span>
          <div class="sort_icon">
            <div
              class="triangle_top_box"
              :class="sortCount === 1 ? 'top_active' : ''"
              @click="handleSort"
            ></div>
            <div
              class="triangle_bottom_box"
              :class="sortCount === 2 ? 'bottom_active' : ''"
              @click="handleSort"
            ></div>
          </div>
        </div>
      </div>
      <div class="row_right">
        <!-- <div class="icon"></div> -->
        <div class="txt color1">最近更新企业资料时间：</div>
        <div class="txt color1">2023-04-13 10:56</div>
      </div>
    </div>
    <div class="card_list">
      <div class="item_card" v-for="(item, index) in cardList" :key="index">
        <div class="item_checkbox">
          <el-checkbox
            @change="itemCheckChange($event, item)"
            v-model="item.checked1"
            label=""
          ></el-checkbox>
        </div>
        <div class="card_content">
          <div class="pos_time">
            <i class="el-icon-date color1"></i>
            <div class="ft-14 color4">账号周期：{{ item.updateTime }}</div>
          </div>
          <div class="card_left">
            <div class="logo_box">
              <img v-if="item.photoUrl" :src="item.photoUrl" alt="">
              <img v-else src="../../../assets/image/yunying.png" alt="" />
            </div>
            <div class="info_box">
              <div class="info_name">{{ item.operatorName }}</div>
              <div class="row">
                <div class="ft-14 color2 txt"></div>
                <div class="ft-14 color2"></div>
              </div>
              <div class="row">
                <span class="ft-14 color2">{{ item.province }}</span>
                <span class="ft-14 color2" v-if="item.city">-{{ item.city }}</span>
                <span class="ft-14 color2" v-if="item.district">-{{ item.district }}</span>
              </div>
            </div>
          </div>
          <div class="card_right">
            <div class="btn_list">
              <el-button @click="jumpZone" type="primary" v-if="item.type==1" class="back_color">电信专区</el-button>
              <el-button type="primary">分配权限</el-button>
              <el-button plain @click="accountClick(item)">账号管理</el-button>
              <el-button class="btn3">关联企业</el-button>
              <span class="color1">编辑</span>
              <span class="color3">冻结</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { operatorPage } from '@/api/operatorCenter'
import CitySelect from '../components/CitySelect'

const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  provinces: '',
  city: '',
  district: '',
  orderByParam: 'DESC' // 默认传1 2->更新时间降序;3->更新时间升序
})
export default {
  name: 'OperatorManage',
  components: {
    CitySelect
  },
  data() {
    return {
      sortVal: 'up', // 排序点击事件的传参
      sortCount: 0, // 排序计数
      cardList: [],
      ids: [], //
      idsList: [], //
      total: 0,
      checkAll: false,
      isIndeterminate: false, //显示半选图标
      queryInfo: { ...defaultQueryInfo } //查询传参
    }
  },
  created() {
    // this.search()
    if (this.$route.query.changeNum) {
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    } else {
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    //电信专区
    jumpZone(){
      this.$router.push({
        path: '/operatorcenter/telezone',
       
      })
    },
    // 点击账号管理
    accountClick(row) {
      this.$router.push({
        path: '/operatorcenter/account',
        query: {
          id: row.id
        }
      })
    },
    // 查询一栏
    async search() {
      const { data: res } = await operatorPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.cardList = res.data.list
        this.cardList.push(
         
          {
            type:1,
            operatorName:'贵阳电信',
            province:'贵阳省',
            city:'贵阳市',
            photoUrl:require('../../../assets/image/dianxinzhuanqu.png')
          }
        )
        this.total = res.data.total
      }
    },

    //排序
    handleSort() {
      this.sortCount++
      if (this.sortCount === 1) {
        this.queryInfo.orders = 1
        this.search()
      } else if (this.sortCount === 2) {
        this.queryInfo.orders = 2
        this.search()
      } else {
        this.sortCount = 0
        this.queryInfo.orders = 0
        this.search()
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化 配置智参中心
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      localStorage.setItem('paginationCurrentPage', newCurrent)
      this.search()
    },
    //全选框
    checkAllChanged(bol) {
      //bol为布尔值，true是选中，false是取消
      if (bol) {
        this.cardList.forEach((item) => {
          this.ids.push(item.id)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.cardList.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = false
    },
    // 多选框选中数据
    itemCheckChange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.id)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
      if (bol) {
        if (this.ids.length == this.cardList.length) {
          this.isIndeterminate = false
          this.checkAll = true
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      } else {
        if (this.ids.length < 1) {
          this.isIndeterminate = false
          this.checkAll = false
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      }
    },
    // 点击新增
    addClick() {},
    //返回省
    searchProvince(val) {
      this.queryInfo.pageNum = 1
      this.queryInfo.provinces = val
      this.search()
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
    //返回区/县
    searchDistrict(val) {
      this.queryInfo.district = val
      this.search()
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  font-family: 'PingFang SC';

  .input_box {
    width: 300px;
  }
  .search_right {
    width: 460px;
    margin-left: auto;
    margin-right: 20px;
  }
  .search_right ::v-deep .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
  .btn_list {
    display: flex;
    align-items: center;
    .back_color{
      background: #565fff !important;
    }
    span {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .row {
    display: flex;
  }
}
.new_page {
  width: 100%;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  // border-top: #fafafa 1px solid;
}

.top_card {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  padding-top: 20px;
  box-sizing: border-box;
}
.center_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .row_left,
  .row_right {
    display: flex;
    align-items: center;
  }
  .row_left {
    display: flex;
    .item {
      .txt {
        font-size: 16px;
        line-height: 24px;
        color: #393939;
      }
    }
    .sort {
      display: flex;
      align-items: center;
      margin-left: 20px;
      span {
        cursor: pointer;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #595959;
        margin-right: 5px;
      }
      .sort_icon {
        cursor: pointer;
        display: flex;
        flex-flow: column;
      }
    }
  }
  .row_right {
    margin-right: 20px;
    .txt {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.card_list {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100% - 228px);
  overflow-y: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .item_card {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .item_checkbox {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
    .card_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      background-color: #fff;
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;
      position: relative;

      .pos_time {
        position: absolute;
        top: 10px;
        right: 20px;
        display: flex;
        align-items: center;
        i {
          font-size: 14px;
          margin-right: 6px;
        }
      }
      .card_left {
        display: flex;
        align-items: center;
        padding-top: 10px;
        box-sizing: border-box;
        .logo_box {
          width: 136px;
          height: 88px;
          margin-right: 10px;
          border-radius: 8px;
          overflow: hidden;
          img {
            width: 136px;
            height: 88px;
          }
        }
        .info_box {
          display: flex;
          flex-flow: column;
          .info_name {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: #151515;
            margin-bottom: 20px;
          }
          .row {
            align-items: center;
            .txt {
              margin-right: 20px;
            }
          }
        }
      }
      .card_right {
        display: flex;
        align-items: flex-end;
        margin-top: auto;
        margin-right: 20px;
      }
    }
  }
}

.top_card ::v-deep .city-select .dic-select {
  width: 188px;
}
.el-form-item ::v-deep .el-form-item__label {
  color: #7c7f8e;
}
.el-form-item ::v-deep .el-input {
  width: 100%;
}
.el-form-item ::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.el-form-item__content ::v-deep .upload-demo {
  width: 100px;
}
.el-radio-button ::v-deep .el-radio-button__inner {
  border-radius: 4px !important;
  border: 0 !important;
}
.ft-14 {
  font-size: 14px;
  line-height: 20px;
}
.color1 {
  color: #4e93fb;
}
.color2 {
  color: #333;
}
.color3 {
  color: #fd5469;
}
.color4 {
  color: #969495;
}
</style>
